console.log('main.js in DEV');

if(!window.raf) {
    window.raf = requestAnimationFrame || mozRequestAnimationFrame || webkitRequestAnimationFrame || msRequestAnimationFrame;
}

var pjax = null,
    swiper = null,
    gallery_items = {},
    current_gallery = null,
    scroll_ticking = false,
    last_known_scroll_position = 0,
    body = document.body,
    back_to_top_el = document.querySelector('.back-to-top'),
    toggle_main_menu_el  = document.querySelector('.toggle-main-menu'),

    is_live = function() {
        return(!body.hasAttribute('data-fee-base-url'));
    },

    // one time setup, first/initial page load
    boot = function() {
        var els, el, i, l;

        document.documentElement.classList.add(('ontouchstart' in window) ? 'touchevents' : 'no-touchevents');

        FastClick.attach(body);

        // mobile navigation
        els = document.querySelectorAll('.toggle-main-menu, .nav-overlay');
        for(i = 0, l = els.length; i < l; i++) {
            els[i].addEventListener('click', _handle_toggle_menu, false);
        }

        // show back to top
        window.addEventListener('scroll', _handle_scroll, false);
        back_to_top_el.addEventListener('click', _handle_back_to_top, false);

        // initialize current page
        on_page_load();

        // initialize pjax
        if(is_live()) {
            pjax = new Pjax({
                // elements that trigger pjax
                elements    : 'a:not(.gallery-for-photoswipe-item)',
                // selectors for elements that get content replaced on pjax navigation
                selectors   : ['title', '#page-container', '#main-menu'],
                analytics   : _handle_pjax_analytics,
                cacheBust   : false,
                scrollTo    : false // we will scroll ourselves
            });

            document.addEventListener('pjax:send', _handle_pjax_send, false);
            document.addEventListener('pjax:complete', _handle_pjax_complete, false);
        }

        // first hamburger roll
        setTimeout(function() {
            toggle_main_menu_el.classList.add('hamburger-roll');
        }, 1);

        // initial virtual pageview
        _handle_pjax_analytics();
    },

    // per page setup, use for elements that might get rendered after a pjax request
    on_page_load = function() {
        var els, el, i, l;

        // contact form
        if(el = document.getElementById('contact-form')) {
            el.addEventListener('submit', _handle_contact_form_submission, false);

            // reset input validation on element value change
            els = document.querySelectorAll('#contact-form input, #contact-form textarea');
            for(i = 0, l = els.length; i < l; i++) {
                els[i].addEventListener('change', _handle_contact_form_changed, false);
            }
        }

        // galleries
        if(document.querySelectorAll('.gallery-for-photoswipe').length) {
            setup_photoswipe();
        }
    },

    _handle_scroll = function() {
        last_known_scroll_position = window.scrollY;

        if(!scroll_ticking) {
            raf(function() {
                if(last_known_scroll_position > window.innerHeight) {
                    back_to_top_el.classList.add('active');
                } else {
                    back_to_top_el.classList.remove('active');
                }
                scroll_ticking = false;
            });
        }

        scroll_ticking = true;
    },

    _handle_back_to_top = function(event) {
        event.preventDefault();

        // window.scroll(0,0);
        zenscroll.toY(0);
    },

    _handle_pjax_send = function() {
        body.classList.add('pjax-loading');

        if(body.classList.contains('nav-open')) {
            toggle_menu();
        }
        if(swiper) {
            swiper.close();
        }
        setTimeout(function() {
            zenscroll.toY(0);
        }, 400);
    },

    _handle_pjax_complete = function() {
        body.classList.remove('pjax-loading');

        on_page_load();
    },

    _handle_pjax_analytics = function() {
        // console.log(arguments, '_handle_pjax_analytics');
        console.log(document.querySelector('title').innerHTML, 'title');
        console.log(window.location, 'location');
        if(window.dataLayer) {
            // console.log('gtm dataLayer present');
            try {
                dataLayer.push({
                    'event'             : 'VirtualPageview',
                    'virtualPageURL'    : window.location.pathname,
                    'virtualPageTitle'  : document.querySelector('title').innerHTML
                });
            } catch(e) {

            }
        }
    },

    _handle_toggle_menu = function(event) {
        event.preventDefault();

        toggle_menu();
    },

    toggle_menu = function() {
        body.classList.toggle('nav-open');

        toggle_main_menu_el.classList.remove('hamburger-roll');

        if(body.classList.contains('nav-open')) {
            toggle_main_menu_el.classList.remove('hamburger');
            toggle_main_menu_el.classList.add('arrow');
        } else {
            toggle_main_menu_el.classList.remove('arrow');
            toggle_main_menu_el.classList.add('hamburger');
        }
    },

    _handle_contact_form_submission = function(event) {
        var form = this,
            data = {},
            els, el, i, l;

        event.preventDefault();

        if(!form.classList.contains('submitting')) {
            form.classList.add('submitting');
            form.classList.remove('error');

            if(els = form.querySelectorAll('input, textarea, select')) {
                for(i = 0, l = els.length; i < l; i++) {
                    el = els[i];
                    data[el.getAttribute('name')] = el.value;
                }
            }

            ajax({
                url     : form.getAttribute('action'),
                method  : form.getAttribute('method'),
                data    : data
            })
            .then(function(response, xhr) {
                if(response) {
                    if(response.success) {
                        form.classList.add('sent');
                    } else {
                        if(response.errors) {
                            for(var i = 0, l = response.errors.length, el; i < l; i++) {
                                if(el = form.querySelector('[name="' + response.errors[i].field + '"]')) {
                                    el.parentElement.classList.add('failed');
                                    el.parentElement.classList.add('failed-' + response.errors[i].rule);
                                }
                            }
                        } else {
                            form.classList.add('error');
                        }
                    }
                } else {
                    form.classList.add('error');
                }
            })
            .catch(function(responseError, xhr) {
                form.classList.add('error');
            })
            .always(function(response, xhr) {
                form.classList.remove('submitting');
            });
        }
    },

    _handle_contact_form_changed = function(event) {
        this.parentElement.classList.remove('failed');
        this.parentElement.classList.remove('failed-validate_required');
        this.parentElement.classList.remove('failed-validate_email');
    },

    setup_photoswipe = function() {
        var initial_gallery     = document.querySelector('.gallery-for-photoswipe[data-initial-item-id]'),
            initial_gallery_id  = initial_gallery ? initial_gallery.getAttribute('data-gallery-id') : '',
            initial_item_id     = initial_gallery ? initial_gallery.getAttribute('data-initial-item-id') : '',
            initial_item        = initial_item_id ? document.getElementById(initial_item_id) : null,
            gels, gel, gid, els, el, gi, gl, i, l, img;

        // fill the gallery_items lists and set click handlers
        if(gels = document.querySelectorAll('.gallery-for-photoswipe')) {
            for(gi = 0, gl = gels.length; gi < gl; gi++) {
                gel = gels[gi];
                gid = gel.getAttribute('data-gallery-id');
                gallery_items[gid] = [];
                if(els = gel.querySelectorAll('.gallery-for-photoswipe-item')) {
                    for(i = 0, l = els.length; i < l; i++) {
                        el = els[i];
                        img = el.querySelector('img');

                        el.setAttribute('data-item-idx', '' + i);
                        el.setAttribute('data-gallery-id', gid);

                        el.addEventListener('click', _handle_gallery_item_click, false);

                        gallery_items[gid].push({
                            src             : el.getAttribute('data-src'),
                            msrc            : img.getAttribute('src'),
                            w               : parseInt(el.getAttribute('data-width'), 10),
                            h               : parseInt(el.getAttribute('data-height'), 10),
                            el              : el,
                            imgEl           : img,
                            caption         : el.querySelector('.photoswipe-content').outerHTML,
                            title           : img.getAttribute('alt'),
                            imageShareUrl   : el.getAttribute('data-image-share-url'),
                            shareUrl        : el.getAttribute('data-share-url')
                        });
                    }
                }

            }
        }

        // show initial gallery item
        if(initial_gallery_id && initial_item) {
            open_photoswipe(initial_gallery_id, initial_item.getAttribute('data-item-idx'));
        }
    },
    _handle_gallery_item_click = function(event) {
        var idx         = this.getAttribute('data-item-idx'),
            gallery_id  = this.getAttribute('data-gallery-id');

        event.preventDefault();

        if((undefined === window.feeditor) || !window.feeditor.isEditing()) {
            open_photoswipe(gallery_id, idx);
        }
    },
    open_photoswipe = function(gallery_id, idx) {
        current_gallery = gallery_items[gallery_id];

        swiper = new PhotoSwipe(document.querySelector('.pswp'), PhotoSwipeUI_Default, current_gallery, {
            index       : parseInt(idx, 10),
            counterEl   : false,
            history     : false,
            shareEl     : !!(document.querySelector('[data-gallery-id="' + gallery_id + '"]').getAttribute('data-share-items')),
            shareButtons: [
                {
                    id      :'facebook',
                    label   :'Deel op Facebook',
                    url     :'https://www.facebook.com/sharer/sharer.php?u={{url}}'
                },
                {
                    id      :'twitter',
                    label   :'Tweet',
                    url     :'https://twitter.com/intent/tweet?text={{text}}&url={{url}}'
                },
                {
                    id      :'pinterest',
                    label   :'Pin it',
                    url     :'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}'
                }
            ],
            getImageURLForShare: function(shareButtonData) {
                return(swiper.currItem.imageShareUrl || '');
            },
            getPageURLForShare: function(shareButtonData) {
                return(swiper.currItem.shareUrl || '');
            },
            getTextForShare: function(shareButtonData) {
                return(swiper.currItem.title || '');
            },
            addCaptionHTMLFn: function(item, captionEl, isFake) {
                captionEl.innerHTML = item.caption;

                return(true);
            },
            getThumbBoundsFn: function(idx) {
                var thumb       = current_gallery[parseInt(idx, 10)].imgEl,
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect        = thumb.getBoundingClientRect();

                return({
                    x: rect.left,
                    y: rect.top + pageYScroll,
                    w: rect.width
                });
            }
        });
        swiper.listen('afterChange', function() {
            console.log(swiper.currItem, 'afterChange');
        });
        swiper.listen('close', function() {
            console.log(swiper.currItem, 'close');
        });
        swiper.listen('destroy', function() {
            swiper = null;
        });
        swiper.init();
    };


boot();
